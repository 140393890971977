<template>
  <v-card flat>
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="12" sm="12" xs="12" md="12" lg="12" xl="12">
          <accounts-table :accounts="accounts" :loading="loading"></accounts-table>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import * as API from "@/api/api";
import AccountsTable from "../components/accounts/AccountsTable.vue";
import { EventBus } from "@/lib/EventBus";
export default {
  name: "ManageAccounts",
  components: { AccountsTable },
  data() {
    return {
      accounts: [],
    };
  },
  computed:{
    loading() {
      return this.$store.getters["pinaStore/getterLoadingStatus"]("ACCOUNTS");
    }
  },
  mounted() {
    this.fetchAccountsList();
    EventBus.$on("account-added", () => {
      this.fetchAccountsList();
    });
  },
  methods: {
    async fetchAccountsList() {
      try {
        this.$store.commit("pinaStore/SET_LOADING_STATUS", {property:"ACCOUNTS", pending:true});
        let accountsList = await API.getAccountsList({});
        // this.$notify({
        //   group: "divrt",
        //   title: "Success",
        //   text: "Fetched accounts successfully",
        //   type: "success",
        // });
        this.accounts = accountsList?.data?.data ? accountsList.data.data : [];
        this.$store.commit("pinaStore/SET_LOADING_STATUS", {property:"ACCOUNTS", pending:false});
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
