<template>
  <v-card flat>
    <v-card-title class="pa-0">
      <v-container fluid pa-0>
        <v-row no-gutters class="justify-end">
          <v-spacer></v-spacer>
          <v-col cols="3" class="text-end pr-3">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  class="elevation-0"
                  v-bind="attrs"
                  v-on="on"
                  @click="addAccountDialog = true"
                >
                  <v-icon color="primary"> person_add </v-icon>
                </v-btn>
              </template>
              <span>Add account</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row no-gutters class="justify-end">
          <v-col class="align-self-center">
            <span>Accounts</span>
          </v-col>
          <v-col cols="3" class="text-end">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              hide-details
              outlined
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-data-table
        :headers="accountHeaders"
        :items="accounts"
        :search="search"
        class="pt-2"
      >
        <template v-slot:[`item.active`]="{ item }">
          {{ item.active == 1 ? "Active" : "Inactive" }}
        </template>
      </v-data-table>
    </v-card-text>
    <loading-overlay :loading="loading"></loading-overlay>
    <v-dialog v-model="addAccountDialog" max-width="600">
      <v-card style="background-color: #f4f6f9">
        <v-card-title class="headline text-center">
          <span>Create New Account</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center pt-3">
          <v-container fluid pa-0>
            <v-row no-gutters>
              <v-col cols="3" class="text-right pr-2 align-self-center">
                <span>Zone Name</span>
              </v-col>
              <v-col cols="9">
                <v-select
                  solo
                  flat
                  v-model="selectedZones"
                  :items="zones"
                  item-text="name"
                  hide-details
                  item-value="zid"
                  label="Select zone"
                  multiple
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="toggle">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            selectedZones.length > 0
                              ? 'indigo darken-4'
                              : 'black'
                          "
                        >
                          {{ selectAllIcon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title> Select All </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.name }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text caption">
                      (+{{ selectedZones.length - 1 }} others)
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="3" class="text-right pr-2 align-self-center">
                <span>Account Type</span>
              </v-col>
              <v-col cols="9">
                <v-container fluid pa-0>
                  <v-row no-gutters>
                    <v-col cols="4">
                      <v-checkbox
                        v-model="account.acntType"
                        label="Spotman"
                        value="ADMIN"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="4">
                      <v-checkbox
                        v-model="account.acntType"
                        label="Config App"
                        value="CA"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="4">
                      <v-checkbox
                        v-model="account.acntType"
                        label="Demo App"
                        value="MA"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="3" class="text-right pr-2 align-self-center">
                <span>Email</span>
              </v-col>
              <v-col cols="9">
                <v-text-field solo flat hide-details v-model="account.email">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="3" class="text-right pr-2 align-self-center">
                <span>Password</span>
              </v-col>
              <v-col cols="9">
                <v-text-field
                  solo
                  flat
                  v-model="account.password"
                  :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                  hide-details
                  :type="showPassword ? 'text' : 'password'"
                  name="password"
                  counter
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <v-btn text @click="addAccountDialog = false">Cancel</v-btn>
          <v-btn color="primary" class="elevation-0" @click="addAccount()"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import * as API from "@/api/api";
import { EventBus } from "@/lib/EventBus";
import { mapGetters } from "vuex";
export default {
  name: "AccountsTable",
  props: {
    accounts: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data() {
    return {
      selectedZones: [],
      showPassword: false,
      account: {
        email: "",
        acntType: [],
        password: "",
      },
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
      search: "",
      accountHeaders: [
        {
          text: "Email",
          align: "left",
          filterable: false,
          value: "email",
        },
        { text: "Account Type", value: "accType", align: "left" },
        { text: "Name", value: "email", align: "left" },
        { text: "Created At", value: "created_at", align: "left" },
        { text: "Created By", value: "createdBy", align: "left" },
        { text: "Zone Access", value: "accessTozone", align: "left" },
        { text: "Status", value: "active", align: "left" },
      ],
      addAccountDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      zones: "pinaStore/getterZones",
    }),
    selectAllIcon() {
      if (this.selectedZones.length == this.zones.length) return "close";
      if (
        this.selectedZones.length != this.zones.length &&
        this.selectedZones.length > 0
      )
        return "remove";
      return "check_box_outline_blank";
    },
  },
  watch: {
    checkoutDate(val) {
      this.dateFormatted = this.formatDate(val);
    },
  },
  methods: {
    async addAccount() {
      try {
        //         divrt: 9e41f3dd8d094d0d9e0510cbdbe099dd1fc73fd7e2b27028e9199e04f525b364
        // zid: 145,161,3
        // loginType[]: ADMIN
        // loginType[]: CA
        // me: raj@divt.co
        // pwp: 1234
        let zonesSelected =
          this.selectedZones.length == this.zones.length
            ? 0
            : this.selectedZones.join(",");
        let addAcc = await API.addAccount({
          zid: zonesSelected,
          loginType: this.account.acntType,
          me: this.account.email,
          pwp: this.account.password,
        });
        this.addAccountDialog = false;
        EventBus.$emit("account-added");
        this.$notify({
          group: "divrt",
          title:
            addAcc?.data?.status && addAcc.data.status ? "Success" : "Error",
          text: addAcc?.data?.message ? addAcc.data.message : "",
          type:
            addAcc?.data?.status && addAcc.data.status ? "success" : "error",
        });
      } catch (error) {
        console.log(error);
      }
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectedZones.length == this.zones.length) {
          this.selectedZones = [];
        } else {
          this.selectedZones = this.zones.slice();
        }
      });
    },
  },
};
</script>
<style lang="sass">
</style>